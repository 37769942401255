import React, { useEffect, useState } from 'react';
import './style.scss';
import { tags } from '../../utils/constants';

interface TagsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (row: any) => void;
  row?: any;
}

const TagsDialog: React.FC<TagsDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  row
}) => {
  const [tempRow, setTempRow] = useState(row)

  useEffect(() => {
    setTempRow(row)
  }, [row])

  if (!isOpen) return null;

  const toggleTag = (id: string) => {
    setTempRow((prev: any) => ({ ...prev, [id]: !tempRow?.[id] }))
  };

  const handleReset = () => {
    setTempRow(row);
  };

  const handleSave = () => {
    onSave(tempRow);
    onClose();
    setTempRow({});
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTempRow((prev: any) => ({ ...prev, notes: e.target.value }))
  };

  return (
    <div className="tags-dialog-overlay">
      <div className="tags-dialog-container">
        <div className="tags-dialog-header">
          <button
            onClick={() => {
              setTempRow({});
              onClose()
            }}
            className="tags-dialog-close-button"
          >
            ×
          </button>
        </div>

        <div className="tags-dialog-content">
          <div className="tags-dialog-tag-container">
            {tags.map(tag => (
              <button
                key={tag.id}
                className={`tags-dialog-tag ${tempRow?.[tag.id] ? 'tags-dialog-tag-selected' : ''}`}
                onClick={() => toggleTag(tag.id)}
              >
                {tag.name}
              </button>
            ))}
          </div>

          <div className="tags-dialog-notes-container">
            <textarea
              className="tags-dialog-notes"
              placeholder="Add notes here..."
              value={tempRow?.notes || ""}
              onChange={handleNotesChange}
              rows={3}
            ></textarea>
          </div>
        </div>

        <div className="tags-dialog-actions">
          <button
            onClick={handleReset}
            className="tags-dialog-button reset-button"
          >
            Reset
          </button>

          <button
            onClick={handleSave}
            className="tags-dialog-button save-button"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagsDialog;