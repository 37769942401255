import { BaseService } from "./base.service";
import { requestFCMPermission } from '../utils/firebase';


export class SubscribeService extends BaseService {
    public async subscribe(swData: any) {
        try {
            const { data, status } = await this.httpClient.post("subscribe-worker", swData);
            if (status === 200) {
                return data;
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            throw error;
        }
    }

    public async requestNotificationPermission() {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                // Get Firebase FCM token
                const fcmToken = await requestFCMPermission();
                return { fcmToken };
            }
            throw new Error('Notification permission denied');
        } catch (error) {
            console.error('Error requesting notification permission:', error);
            throw error;
        }
    }

    // Initialize service worker for notifications
    public async initializeNotifications() {
        if ('serviceWorker' in navigator) {
            try {
                // Register Firebase service worker
                const firebaseRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');

                console.log('Firebase SW registered:', firebaseRegistration.scope);

                // Request permission if notifications are supported
                if (Notification.permission === 'granted') {
                    return await this.requestNotificationPermission();
                }
            } catch (error) {
                console.error('Service Worker registration failed:', error);
            }
        }
    }

    public async saveFCMTokenToServer(token: string) {
        try {
            const { data, status } = await this.httpClient.post("notification/fcm-token", { token });

            if (status === 200) {
                return data;
            }

        } catch (error) {
            console.error('Error saving FCM token:', error);
        }
    };
}