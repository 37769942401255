import { Box, Link } from "@mui/material";
import React from "react";
import "./styles.scss";

const FooterBarLogin: React.FC = () => {
  return (
    <Box className="bottom-login-bar-container">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Link
          href="https://www.pocketbud.io/terms"
          style={{ cursor: "pointer" }}
          underline="none"
        >
          Terms
        </Link>
        <Link
          href="https://pocketbud.io/privacy-policy"
          style={{ cursor: "pointer" }}
          underline="none"
        >
          Privacy
        </Link>
        <Link href="https://pocketbud.io/refund" underline="none">
          Refund policy
        </Link>
      </div>
    </Box>
  );
};

export default FooterBarLogin;
