// import React from 'react';
// import { onForegroundMessage } from './firebase';
// import { toast } from 'react-hot-toast';
import { SubscribeService } from '../services/subscribe.service';

// Initialize Firebase messaging
export const initializeFirebaseMessaging = async () => {
  try {
    const subscribeService = new SubscribeService();
    // Initialize notification service workers
    await subscribeService.initializeNotifications();

    // // Listen for foreground messages
    // onForegroundMessage((payload) => {
    //   console.log('Message received in foreground111:', payload);

    //   // Display a toast notification for foreground messages
    //   toast.custom((t) => (
    //     <div className={`${t.visible ? 'animate-enter' : 'animate-leave'} 
    //                     max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto
    //                     flex ring-1 ring-black ring-opacity-5`}>
    //       <div className="flex-1 w-0 p-4">
    //         <div className="flex items-start">
    //           <div className="flex-shrink-0 pt-0.5">
    //             <img className="h-10 w-10 rounded-full" src="/logo192.png" alt="" />
    //           </div>
    //           <div className="ml-3 flex-1">
    //             <p className="text-sm font-medium text-gray-900">
    //               {/* {payload.notification?.title || 'PocketBud Notification'} */}
    //               {'PocketBud Notification111'}
    //             </p>
    //             <p className="mt-1 text-sm text-gray-500">
    //               {/* {payload.notification?.body || 'You have a new notification'} */}
    //               {'You have a new notification123'}
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="flex border-l border-gray-200">
    //         <button
    //           onClick={() => toast.dismiss(t.id)}
    //           className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
    //         >
    //           Dismiss
    //         </button>
    //       </div>
    //     </div>
    //   ), {
    //     duration: 5000,
    //   });
    // });

    console.log('Firebase messaging initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase messaging:', error);
  }
};

export default initializeFirebaseMessaging; 