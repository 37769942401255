export const Months = [
    { value: "Jan", id: 1 },
    { value: "Feb", id: 2 },
    { value: "Mar", id: 3 },
    { value: "Apr", id: 4 },
    { value: "May", id: 5 },
    { value: "Jun", id: 6 },
    { value: "Jul", id: 7 },
    { value: "Aug", id: 8 },
    { value: "Sep", id: 9 },
    { value: "Oct", id: 10 },
    { value: "Nov", id: 11 },
    { value: "Dec", id: 12 },
];

export const QuantTopics = ["Problem Solving (PS)"]

export const DiTopics = ['Multi-Source Reasoning (MSR)', 'Data Sufficiency (DS)', 'Two-part Analysis (TPA)']

export const VerbalTopics = ['Critical Reasoning (CR)', 'Reading Comprehension (RC)', 'Sentence Correction (EA only)']

export enum TemplateId {
    GMATClub = 'GMATClub',
    EGMAT = 'e-gmat',
    TESTPREP = 'targetTestPrep',
    GMATWHIZ = 'gmat-whiz',
    MBA = 'mba'
}

export const DefaultTemplate = {
    error_template_id: '',
    section: 'Section',
    category: 'Category',
    subcategories: 'Subcategories',
    question_type: 'Question Type',
    topic_names: 'Topics',
    subtopic_names: 'Subtopics',
    brief_question_text: 'Brief Question Text',
    link_to_question: 'Link to Question',
    bookmarked: 'Bookmarked',
    solution: "",
    guessed: 'Guessed',
    selected_option: "",
    date_attempted: 'Date',
    time_taken: 'Time taken',
    performance: 'Performance',
    difficulty: 'Difficulty',
    question_source: 'Source',
    careless_mistake: 'Careless Mistake',
    anxiety: 'Anxiety',
    conceptual_gap: 'Conceptual Gap',
    time_mismanagement: 'Time Mismanagement',
    vocabulary_void: 'Vocabulary Void',
    comprehension_error: 'Comprehension Error',
    description: 'Description',
    learnings: 'Learnings',
    notes: 'Notes',
    question_id: '',
    user_id: '',
}

export const templatePatterns = {
    "e-gmat": [
        /^\d+\s+[A-Z]{2,3}\s+[\w\s]+\s+[E|EM]\s+\[x?\]\s+\d{2}:\d{2}/m,
        /^\d+\s+[A-Za-z]+\s+\d+\s+[A-Za-z\s]+(Correct|Incorrect)\s+om\s+\d+s\s+NA\s+NA\s+[A-Za-z]+$/m,
    ],
    targettestprep: /[@|©]\s*Question\d+\s+PROBLEM SOLVING/,
    gmatwhiz: /^\d+\s+\d{3}-\d{3}\s+Level\s+\d{1,3}:\d{2}\s+MM:SS/m,
    mba: /^\d+\s+\d+\.\d{2}\s+(Correct|Incorrect)\s+[A-Za-z\s]+(?:\s+[A-Za-z\s]+)?$/m,
};


export const GMATClubTemplate: any = {
    category: 'category',
    topic_names: 'topic',
    question_type: "questionType",
    subtopic_names: "subtopic",
    link_to_question: 'Link to question',
    bookmarked: 'Bookmarked',
    solution: "Solution",
    selected_option: 'My Answer',
    date_attempted: 'Date',
    time_taken: 'Time taken',
    difficulty: 'Difficulty',
    question_source: 'Source',
    section: "section"
}

export const GMATClubTemplate2: any = {
    category: "category",
    topic_names: "topic",
    question_type: "questionType",
    subtopic_names: "subtopic",
    link_to_question: "Link to question",
    bookmarked: "Bookmarked",
    solution: "Result",
    selected_option: "My Answer",
    date_attempted: "Date",
    time_taken: "Time taken",
    difficulty: "Difficulty",
    question_source: "Source",
    section: "section",
};

export enum SubscriptionStatus {
    Inactive = 'Inactive',
    Active = 'Active',
    Expired = 'Expired'
}

export enum SubscriptionPlane {
    Free = 'Free',
    Premium = "Premium"
}

export enum ForceReviewSettingEnum {
    All = 'All',
    Only_Incorrect = "Only_Incorrect"
}

export enum Modes {
    Light = 'light',
    Dark = "dark"
}

export const ColorPalette = {
    light: {
        pieColorPalette: [
            "#E9AE3D",
            "#EA8934",
            "#27931D",
            "#6156A6",
            "#6D84BF",
            "#B23D75",
        ],
        CartesianGrid: "#DEDEDE",
        topLineCharts: "#4B7D8F",
        weekelyLineCharts: "#EA8934",
        accuracyPieCorrect: "#125167",
        accuracyPieIncorrect: "#EA8934",
        pieFill: "#EFEDEC",
        pieLabels: "#252525",
        pieLabels2: "#125167",
        pieLabels3: "#125167",
        ConnectingLine: "#125167"
    },
    dark: {
        pieColorPalette: [
            "#FAE38E",
            "#FCAC63",
            "#BB86FC",
            "#3700B3",
            "#D0667A",
            "#63FCD7",
            "#6D84BF",
            "#CCD6F6",
        ],
        CartesianGrid: "#252525",
        topLineCharts: "#FCAC63",
        weekelyLineCharts: "#FCAC63",
        accuracyPieCorrect: "#FAE38E",
        accuracyPieIncorrect: "#FCAC63",
        pieFill: "#121212",
        pieLabels: "#FCAC63",
        pieLabels2: "#FFFFFF",
        pieLabels3: "#FCAC63",
        ConnectingLine: "#FCAC63"
    }
}

export const errorLogTableHeaders = [
    { id: "q_no", label: "Question No" },
    { label: "Question Type", id: "question_type" },
    { label: "Tags", id: "tags" },
    { label: "Topic Name", id: "topic_names" },
    { label: "Date", id: "date_attempted" },
    { label: "Time Taken", id: "time_taken" },
    { label: "Solution", id: "solution" },
    { label: "Notes", id: "notes" },
    { label: "Difficulty", id: "difficulty" }
]

export const gmatClubHeaders = [
    { id: "q_no", label: "Question No", isEditable: false },
    { id: "category", label: "Category", isEditable: false },
    { id: "topic_names", label: "Topic Names", isEditable: false },
    { id: "question_type", label: "Question Type", isEditable: false },
    { id: "subtopic_names", label: "Sub Topic Type", isEditable: false },
    { id: "link_to_question", label: "Link To Question", isEditable: false },
    { id: "bookmarked", label: "Bookmarked", isEditable: false },
    { id: "solution", label: "Solution", isEditable: false },
    { id: "selected_option", label: "Selected Option", isEditable: false },
    { id: "date_attempted", label: "Date Attempted", isEditable: false },
    { id: "time_taken", label: "Time Taken", isEditable: false },
    { id: "difficulty", label: "Difficulty", isEditable: false },
    { id: "question_source", label: "Question Source", isEditable: false },
    { id: "is_first_question", label: "Is First Question", type: "checkbox", isEditable: true },
]

export const gmatWhizHeaders = [
    { id: "q_no", label: "Question No", isEditable: false },
    { id: "solution", label: "Solution", isEditable: false },
    { id: "difficulty", label: "Difficulty", isEditable: false },
    { id: "time_taken", label: "Time Taken", isEditable: false },
    { id: "is_first_question", label: "Is First Question", type: "checkbox", isEditable: true },
]
export const eGmatHeaders = [
    { id: "q_no", label: "Question No", isEditable: false },
    { id: "question_type", label: "Question Type", isEditable: false },
    { id: "category", label: "Category", isEditable: false },
    { id: "difficulty", label: "Difficulty", isEditable: false },
    { id: "solution", label: "Solution", isEditable: false },
    { id: "time_taken", label: "Time Taken", isEditable: false },
    { id: "date_attempted", label: "Date Attempted", isEditable: false },
    { id: "is_first_question", label: "Is First Question", type: "checkbox", isEditable: true },
]
export const testPrepHeaders = [
    { id: "q_no", label: "Question No", isEditable: false },
    { id: "question_type", label: "Question Type", isEditable: false },
    { id: "difficulty", label: "Difficulty", isEditable: false },
    { id: "solution", label: "Solution", isEditable: false },
    { id: "time_taken", label: "Time Taken", isEditable: false },
    { id: "date_attempted", label: "Date Attempted", isEditable: false },
    { id: "is_first_question", label: "Is First Question", type: "checkbox", isEditable: true },
]

export const mbaHeaders = [
    { id: "q_no", label: "Question No", isEditable: false },
    { id: "question_type", label: "Question Type", isEditable: false },
    { id: "time_taken", label: "Time Taken", isEditable: false },
    { id: "solution", label: "Solution", isEditable: false },
    { id: "question_skillset", label: "Question Skillset", isEditable: false },
    { id: "is_first_question", label: "Is First Question", type: "checkbox", isEditable: true },
]

export const tags = [
    { id: 'guessed', name: 'Guessed' },
    { id: 'is_first_question', name: 'Is First Question' },
    { id: 'careless_mistake', name: 'Careless Mistake' },
    { id: 'conceptual_gap', name: 'Conceptual Gap' },
    { id: 'time_mismanagement', name: 'Time Mismanagement' },
    { id: 'vocabulary_void', name: 'Vocabulary Void' },
    { id: 'comprehension_error', name: 'Comprehension Error' }
  ]

export enum WebsocketEvents {
    ReceivedNewNotification = 'ReceivedNewNotification',
    MarkAsRead = "MarkAsRead"
}