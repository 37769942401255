import React, { useEffect } from "react";
import "./styles.scss";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setShowThankYouPage } from "../../redux/Reducer/userReducer";
import { useNavigate } from "react-router-dom";

const PaymentCompleted: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isShowThankYouPage = useSelector(
    (state: any) => state?.userDetails?.isShowThankYouPage
  );

  useEffect(() => {
    if (!isShowThankYouPage) navigate("/dashboard");
    const redirectTimer = setTimeout(() => {
      dispatch(setShowThankYouPage({ isShowThankYouPage: false }));
      navigate("/dashboard");
    }, 5000);
    return () => clearTimeout(redirectTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowThankYouPage]);

  return (
    <div className="overview">
      <Grid container spacing={2} style={{
        height: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center"
      }}>
        Thank You For Subscribing. Redirecting to dashboard in 5 sec...
      </Grid>
    </div>
  );
};

export default PaymentCompleted;
