import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Popover,
  Typography,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TagsDialog from "../TagsDialogBox";
import { tags } from "../../utils/constants";
import './style.scss';

interface Column {
  id: string;
  label: string;
  type?: string;
  minWidth?: number;
}

interface Row {
  [key: string]: any;
}

interface AppTableProps {
  columns: Column[];
  rows: Row[];
  maxVisibleTags?: number;
  setRows?: (_: any) => void;
}

const TagItem: React.FC<{ tag: string, isSelected: boolean }> = ({ tag, isSelected }) => {
  return (
    <Box
      className={`tags-dialog-tag ${isSelected ? "tags-dialog-tag-selected" : ""}`}
    >
      {tag}
    </Box>
  );
};

const AppTable: React.FC<AppTableProps> = ({
  columns,
  rows,
  setRows,
  maxVisibleTags = 2
}) => {
  const [editingRow, setEditingRow] = useState<any>({});
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [expandedTags, setExpandedTags] = useState<any[]>([]);

  const handleEditClick = (row: any) => {
    setIsDialogOpen(true)
    setEditingRow(row);
  };

  const handleSaveClick = (row: any) => {
    const updatedData = rows.map((x) => {
      if (x.q_no === editingRow.q_no) {
        return row
      } return x
    });
    setRows && setRows(updatedData);
    setEditingRow(null); // Stop editing mode
  };

  const handleShowMoreTags = (event: React.MouseEvent<HTMLElement>, tags: any[], row: any) => {
    setAnchorEl(event.currentTarget);
    setEditingRow(row)
    setExpandedTags(tags);
  };

  const handleClosePopover = () => {
    setEditingRow({})
    setAnchorEl(null);
  };

  const renderTags = (row: Row) => {
    const tempTags = tags
      .map((x) => ({ ...x, isSelected: !!row?.[x.id] }))
      .sort((a, b) => {
        // First sort by isSelected (true comes first)
        if (a.isSelected !== b.isSelected) {
          return b.isSelected ? 1 : -1;
        }
        // Then sort alphabetically within each group
        return a.name.localeCompare(b.name);
      });
    const visibleTags = tempTags.slice(0, maxVisibleTags);
    const hiddenTagsCount = tempTags.length - maxVisibleTags;

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        {visibleTags.map((tag: any, index: number) => (
          <TagItem
            key={index}
            tag={tag.name}
            isSelected={!!row?.[tag.id]}
          />
        ))}

        {hiddenTagsCount > 0 && (
          <Box
            sx={{
              display: 'inline-block',
              padding: '5px 12px',
              margin: '3px',
              color: 'white',
              fontSize: '14px',
              cursor: 'pointer',
            }}
            onClick={(e) => handleShowMoreTags(e, tags || [], row)}
          >
            +{hiddenTagsCount} more
          </Box>
        )}

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ maxWidth: 400, padding: 2 }}>
            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>All Tags</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {expandedTags.map((tag: any, index: number) => (
                <TagItem
                  key={index}
                  tag={tag.name}
                  isSelected={!!editingRow?.[tag.id]}
                />
              ))}
            </Box>
          </Box>
        </Popover>
      </Box>
    );
  };

  // Function to render cell content based on column type
  const renderCellContent = (column: Column, row: Row) => {
    if (column.id === "tags") {
      return renderTags(row);
    } else if (column.id === "notes") {
      return (
        <Tooltip
          title={row[column.id] || "-"}
          arrow
          placement="top-start"
          enterDelay={500}
        >
          <Box
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "150px", // Adjust this value as needed
              cursor: "pointer"
            }}
          >
            {row[column.id] || "-"}
          </Box>
        </Tooltip>
      );
    } else if (column.id === "q_no") {
      return (
        <>
          {row[column.id] || "-"}
          <IconButton onClick={() => handleEditClick(row)}>
            <EditIcon />
          </IconButton>
        </>
      );
    } else {
      return row[column.id] || "-";
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={!row.is_uploadable ? { background: "gray", color: "black" } : {}}
                >
                  {renderCellContent(column, row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TagsDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={(row) => handleSaveClick(row)}
        row={editingRow}
      />
    </TableContainer>
  );
};

export default AppTable;