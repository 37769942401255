import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as Book } from "../../assets/light/Book.svg";
import "./styles.scss";
import AppButton from "../../components/AppButton";
import MyCalendar from "../../components/Calendar";
import { StudyPlanService } from "../../services/studyPlan.service";
import { useSelector } from "react-redux";

interface IProps {
  data?: any;
}
interface Event {
  allDay?: boolean | undefined;
  title?: React.ReactNode | undefined;
  start?: Date | undefined;
  end?: Date | undefined;
  resource?: any;
}

const StudyPlan: React.FC<IProps> = ({ data }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const studyPlanService = new StudyPlanService();

  const noOfSections = useSelector(
    (state: any) => state?.studyPlan?.noOfSections
  );

  const getStudyPlan = async () => {
    const data = await studyPlanService.GetStudyPlans();
    if (!!data?.data)
      setEvents(
        data.data.map((x: any) => {
          return {
            ...x,
            start: new Date(x.start.split("+")[0]),
            end: new Date(x.end.split("+")[0]),
          };
        })
      );
  };

  useEffect(() => {
    getStudyPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box className="study-plan-container">
      <Box className="study-plan-view-container">
        <Box className="file-container">
          <Box className="left-space">
            <Box className="icon-container">
              <Book />
            </Box>
            <div className="file-details">
              <p className="file-content">NO OF COURSE SECTIONS</p>
              <p className="file-content bold">{noOfSections || 0}</p>
            </div>
          </Box>
          <AppButton
            label="View Sections"
            variant="outlined"
            customVariant="custom-outlined"
          />
        </Box>

        <Box className="calendar-container">
          <MyCalendar height={"100%"} width={"100%"} eventList={events} />
        </Box>
      </Box>
    </Box>
  );
};

export default StudyPlan;
