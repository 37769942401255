import { TemplateId, templatePatterns } from "../constants";

enum SubjectLevels {
    EM = "555-605",
    E = "605-655",
    M = "655-705",
    MH = "705-805"
}

const Category = ["Quantitative Reasoning", "Verbal Reasoning", "Data Insights"]
const Questiontypes: any = {
    "PS": "Problem Solving",
    "CR": "Critical Reasoning",
    "RC": "Reading Comprehension",
    "SC": "Sentence Correction",
    "DS": "Data Sufficiency",
    "GI": "Graphics Interpretation",
    "GT": "Graphs and Tables",
    "TA": "Table Analysis",
    "TPA": "Two-Part Analysis",
    "MSR": "Multi-Source Reasoning"
}

const Mapper: any = {
    "Quantitative Reasoning": {
        "Problem Solving": [
            {
                "name": "Word Problems",
                "subtopics": [
                    "Distance/Rate Problems",
                    "Distance and Speed Problems",
                    "Geometry",
                    "Mixture Problems",
                    "Overlapping Sets",
                    "Word Problems",
                    "Work/Rate Problems"
                ]
            },
            {
                "name": "Arithmetic and Algebra",
                "subtopics": [
                    "Absolute Values/Modulus",
                    "Algebra",
                    "Arithmetic",
                    "Functions and Custom Characters",
                    "Fractions/Ratios/Decimals",
                    "Fractions and Ratios",
                    "Inequalities",
                    "Min/Max Problems",
                    "Must or Could be True Questions",
                    "Exponents/Powers",
                    "Percent and Interest Problems",
                    "Roots",
                    "Sequences"
                ]
            },
            {
                "name": "Number Theory",
                "subtopics": [
                    "Divisibility/Multiples/Factors",
                    "Multiples and Factors",
                    "Number Properties",
                    "Remainders"
                ]
            },
            {
                "name": "Statistics and Combinations",
                "subtopics": [
                    "Combinations",
                    "Probability",
                    "Statistics and Sets Problems"
                ]
            }
        ]
    },
    "Verbal Reasoning": {
        "Critical Reasoning": [
            "Assumption",
            "Additional Evidence",
            "Bold Face CR",
            "Cause and Effect",
            "Complete the Passage",
            "Conclusion",
            "Evaluate Argument",
            "Except",
            "Inference",
            "Logical Flaw",
            "Method of Reasoning",
            "Must be True",
            "Numbers",
            "Resolve Paradox",
            "Similar Reasoning",
            "Strengthen",
            "Weaken"
        ],
        "Reading Comprehension": [
            "Main Idea questions",
            "Specific detail questions",
            "Inference & Reasoning questions",
            "Vocabulary-related questions (based on the context)",
            "Humanities",
            "Social Science",
            "Short Passage",
            "Long Passage"
        ],
        "Sentence Correction": ["Grammatical/Rhetorical Construction"]
    },
    "Data Insights": {
        "Data Sufficiency": [
            {
                "name": "Word Problems",
                "subtopics": [
                    "Distance/Rate Problems",
                    "Distance and Speed Problems",
                    "Geometry",
                    "Mixture Problems",
                    "Overlapping Sets",
                    "Word Problems",
                    "Work/Rate Problems",
                    "Combinations",
                    "Probability",
                    "Statistics and Sets Problems",
                    "Percent and Interest Problems",
                    "Min/Max Problems",
                    "Non Math Related",
                    "Non-Math Related"
                ]
            },
            {
                "name": "Arithmetic",
                "subtopics": [
                    "Arithmetic",
                    "Fractions/Ratios/Decimals",
                    "Fractions and Ratios",
                    "Roots",
                    "Sequences",
                    "Divisibility/Multiples/Factors",
                    "Multiples and Factors",
                    "Number Properties"
                ]
            },
            {
                "name": "Algebra",
                "subtopics": [
                    "Absolute Values/Modulus",
                    "Algebra",
                    "Inequalities",
                    "Exponents/Powers",
                    "Functions and Custom Characters",
                    "Remainders"
                ]
            }
        ],
        "Graphics Interpretation": [],
        "Graphs and Tables": ["Graphs"],
        "Table Analysis": [],
        "Two-Part Analysis": ["Math Related", "Non-Math Related"],
        "Multi-Source Reasoning": []
    }
}

const parseEachArr = (jsonData: any[], templateId: TemplateId, templateNo: number) => {
    if (templateNo === 1) {
        return jsonData.map((entry: string[]) => {
            if (entry.length < 2) return null;
            let [q_no, question_type, ...subjectAndTopic] = entry;
            subjectAndTopic.pop()
            let timeTaken = subjectAndTopic.pop();
            let isCorrect = subjectAndTopic.pop() === '[]';
            let level = subjectAndTopic.pop();
            let topic_names = subjectAndTopic.join(' ');
            let section = '';
            let subtopic = '';

            question_type = Questiontypes[question_type?.toUpperCase()] || "";

            for (const cat of Category) {
                for (const qType in Mapper[cat]) {
                    if (question_type.toLowerCase().includes(qType.toLowerCase())) {
                        section = cat;
                        question_type = qType;
                        break;
                    }
                }
            }

            if (section && question_type) {
                const topicData = Mapper[section][question_type];
                const tempTopic = topic_names?.split(",")[0].trim()

                for (const top of topicData) {

                    if (!!top?.subtopics?.length) {
                        let sub = top?.subtopics?.find((x: any) => x?.toLowerCase().includes(tempTopic?.toLowerCase()))
                        if (sub) {
                            topic_names = top.name;
                            subtopic = sub;
                        } else if (top?.name?.toLowerCase().includes(tempTopic?.toLowerCase())) {
                            topic_names = top?.name;
                            subtopic = "N/A";
                        }
                    } else if (typeof top === "string" && top?.toLowerCase().includes(tempTopic?.toLowerCase())) {
                        topic_names = top;
                        subtopic = "N/A";
                    }
                }
            }

            return {
                subtopic,
                section,
                q_no: Number(q_no),
                question_type,
                topic_names,
                difficulty: SubjectLevels[level as keyof typeof SubjectLevels] || level,
                solution: isCorrect ? "Correct" : "Incorrect",
                time_taken: timeTaken,
                error_template_id: templateId,
                date_attempted: new Date().toISOString()
            };
        }).filter((x: any) => !!x)
    } else if (templateNo === 2) {
        return jsonData.map((entry: string[]) => {
            if (entry.length < 2) return null;
            let [q_no, difficulty, ...subjectAndTopic] = entry;
            let temp: any = ""
            let question_type = ""
            let solution: any = ""
            let flag = true;
            subjectAndTopic.shift()
            do {
                temp = subjectAndTopic.shift()
                if (["correct", "incorrect"].includes(temp.toLowerCase())) {
                    solution = temp
                    question_type = question_type.trim()
                    flag = false
                } else {
                    question_type = `${question_type} ${temp}`
                }
            } while (flag)
            let min = subjectAndTopic.shift()?.replace("m", "")
            if (typeof min === 'string' && (min.toLowerCase() === "o" || min.toLowerCase() === "oo")) {
                min = '00'
            } else if (typeof min === 'string' && min.length === 1) {
                min = '0' + min
            }
            let sec = subjectAndTopic.shift()?.replace("s", "")
            if (typeof sec === 'string' && (sec.toLowerCase() === "o" || sec.toLowerCase() === "oo")) {
                sec = '00'
            } else if (typeof sec === 'string' && sec.length === 1) {
                sec = '0' + sec
            }
            let timeTaken = `${min}:${sec}`;
            let topic_names = '';
            let section = '';
            let subtopic = '';

            for (const cat of Category) {
                for (const qType in Mapper[cat]) {
                    if (question_type.toLowerCase().includes(qType.toLowerCase())) {
                        section = cat;
                        question_type = qType;
                        break;
                    }
                }
            }

            if (section && question_type) {
                const topicData = Mapper[section][question_type];
                const tempTopic = topic_names?.split(",")[0].trim()

                for (const top of topicData) {

                    if (!!top?.subtopics?.length) {
                        let sub = top?.subtopics?.find((x: any) => x?.toLowerCase().includes(tempTopic?.toLowerCase()))
                        if (sub) {
                            topic_names = top.name;
                            subtopic = sub;
                        } else if (top?.name?.toLowerCase().includes(tempTopic?.toLowerCase())) {
                            topic_names = top?.name;
                            subtopic = "N/A";
                        }
                    } else if (typeof top === "string" && top?.toLowerCase().includes(tempTopic?.toLowerCase())) {
                        topic_names = top;
                        subtopic = "N/A";
                    }
                }
            }

            return {
                subtopic,
                section,
                q_no: Number(q_no),
                question_type,
                topic_names,
                difficulty,
                solution,
                time_taken: timeTaken,
                error_template_id: templateId,
                date_attempted: new Date().toISOString()
            };
        }).filter((x: any) => !!x)
    } return []
};

export const e_gmatMapper = (textStrArr: string[], template: TemplateId) => {
    let templateNo = 0
    const patterns = templatePatterns["e-gmat"]
    if (Array.isArray(patterns)) {
        patterns.forEach((patt, i) => {
            if (patt.test(textStrArr.join("\n"))) {
                templateNo = i + 1
            }
        })
    }
    return textStrArr.reduce((arr: any[], textStr) => {
        const splittedData = textStr.split("\n").map(x => x.split(" ")).filter(x => !isNaN(Number(x?.[0])))
        return [...arr, ...parseEachArr(splittedData, template, templateNo)]
    }, []).filter((x: any, index: number, self) => {
        return index === self.findIndex(y => y.q_no === x.q_no && y.category === x.category)
    })
}