import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Request permission and get FCM token
export const requestFCMPermission = async () => {
  try {
    // Check if the browser supports notifications
    if (!('Notification' in window)) {
      console.error('This browser does not support desktop notification');
      return null;
    }

    // Request notification permission
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      console.error('Notification permission denied');
      return null;
    }

    // Get FCM token
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
    });

    if (currentToken) {

      return currentToken;
    } else {
      console.error('No registration token available');
      return null;
    }
  } catch (error) {
    console.error('An error occurred while retrieving token:', error);
    return null;
  }
};

// Handle foreground messages
export const onForegroundMessage = (callback: (payload: any) => void) => {
  return onMessage(messaging, (payload) => {
    console.log('Message received in foreground222:', payload);

    // Show notification for foreground messages
    if (payload.notification) {
      // const notificationTitle = payload.notification.title || 'PocketBud Notification';
      const notificationTitle = 'PocketBud Notification098';
      const notificationOptions = {
        // body: payload.notification.body || 'New notification',
        body: 'New notification222',
        icon: '/logo192.png',
        badge: '/logo192.png',
        data: payload.data || {},
        actions: [
          {
            action: 'open',
            title: 'View',
          },
          {
            action: 'close',
            title: 'Dismiss',
          }
        ]
      };

      // Show the notification
      if (Notification.permission === 'granted') {
        new Notification(notificationTitle, notificationOptions);
      }
    }

    // Call the callback with the payload
    callback(payload);
  });
};

export { messaging }; 