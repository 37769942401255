import { Box, SxProps, Theme } from "@mui/material";
import React, { useState } from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RAGService } from "../../services/rag.service";
import RagResults from "../../components/AMA";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  data?: any;
}

interface ChatMessage {
  analysis: string;
  timestamp: Date;
  performanceData?: any;
  questionTypeData?: any;
  userQuery?: string;
}

const AMA: React.FC<IProps> = ({ data }) => {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentChatId] = useState<string | undefined>(uuidv4());
  const [analysisData, setAnalysisData] = useState<{
    analysis: string;
    performanceData?: any;
    questionTypeData?: any;
    userQuery?: string;
  } | null>();
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const userId = useSelector((state: any) => state?.userDetails?.userDetails?.userId);

  const ragService = new RAGService();

  const handleSendMessage = async () => {
    if (!input.trim() || isLoading || !userId) return;

    if (analysisData?.analysis) {
      setChatHistory(prev => [...prev, {
        timestamp: new Date(),
        ...analysisData
      }]);
      setAnalysisData(prev => ({
        userQuery: userInput,
        analysis: "",
        performanceData: "",
        questionTypeData: ""
      }))
    }

    setIsLoading(true);
    const userInput = input;

    try {
      await ragService.sendMessage(userId, userInput, currentChatId, (data) => {
        if (data.status === "ok") {
          setAnalysisData(prev => ({
            userQuery: data.userQuery,
            analysis: (prev?.analysis || '') + data.analysis,
            performanceData: data.performanceData || prev?.performanceData,
            questionTypeData: data.questionTypeData || prev?.questionTypeData
          }));
        } else {
          console.error('Error:', data.error);
        }
      });
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
      setInput('');
    }
  };

  // const handleKeyPress = (event: React.KeyboardEvent) => {
  //   if (event.key === 'Enter' && !event.shiftKey) {
  //     event.preventDefault();
  //     handleSendMessage();
  //   }
  // };

  const styles: Record<string, SxProps<Theme>> = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '90vh',
      backgroundColor: (theme) => theme.palette.background.default,
      color: (theme) => theme.palette.text.primary,
      padding: '16px',
      fontFamily: 'Arial, sans-serif'
    },
    content: {
      width: '100%',
      maxWidth: '800px'
    },
    header: {
      fontSize: '32px',
      fontWeight: 'bold',
      marginBottom: '64px',
      textAlign: 'center' as const
    },
    orangeText: {
      color: '#ff7d45'
    },
    chatBox: {
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1a1a1a' : '#f5f5f5',
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '32px'
    },
    amaTextarea: {
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      color: (theme) => theme.palette.text.primary,
      fontSize: '16px',
      resize: 'none',
      outline: 'none',
      minHeight: '100px',
      marginBottom: '16px',
      fontFamily: 'inherit'
    },
    textArea: {
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      color: (theme) => theme.palette.text.primary,
      fontSize: '16px',
      resize: 'none',
      outline: 'none',
      minHeight: '100px',
      marginBottom: '16px',
      fontFamily: 'inherit'
    },
    resultsBox: {
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1a1a1a' : '#f5f5f5',
      borderRadius: '8px',
      marginBottom: '32px',
      width: '100%'
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    flexAlign: {
      display: 'flex',
      alignItems: 'center'
    },
    aiName: {
      color: (theme) => theme.palette.text.secondary
    },
    iconContainer: {
      marginLeft: '8px',
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#374151' : '#e5e7eb',
      borderRadius: '50%',
      padding: '4px'
    },
    icon: {
      width: '16px',
      height: '16px',
      color: (theme) => theme.palette.text.secondary
    },
    askButton: {
      backgroundColor: '#ff7d45',
      color: 'white',
      padding: '8px 16px',
      borderRadius: '6px',
      border: 'none',
      cursor: 'pointer',
      '&:disabled': {
        opacity: 0.7,
        cursor: 'not-allowed'
      }
    },
    recentChatsLabel: {
      color: (theme) => theme.palette.text.secondary,
      marginBottom: '12px'
    },
    chatItem: {
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1a1a1a' : '#f5f5f5',
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '12px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    chatName: {
      color: (theme) => theme.palette.text.secondary
    },
    openLabel: {
      color: (theme) => theme.palette.text.disabled
    },
    streamingAnalysis: {
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1a1a1a' : '#f5f5f5',
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '32px',
      width: '100%'
    }
  };

  return (
    <Box sx={styles.container}>
      {!analysisData && <Box sx={styles.content}>
        {/* Header */}
        <Box sx={styles.header}>
          <Box component="span" sx={styles.orangeText}>Sage AI</Box>
          <Box component="span"> : A NextGen AI Tool</Box>
        </Box>

        {/* Chat Box */}
        <Box sx={styles.chatBox}>
          <Box
            component="textarea"
            placeholder="How can I help you?"
            sx={styles.amaTextarea}
            disabled={isLoading}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />

          <Box sx={styles.flexBetween}>
            <Box sx={styles.flexAlign}>
              <Box component="span" sx={styles.aiName}>Sage AI</Box>
              <Box sx={styles.iconContainer}>
                <svg style={{
                  width: '16px',
                  height: '16px',
                  color: '#d1d5db'
                }} fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
              </Box>
            </Box>
            <button
              onClick={handleSendMessage}
              disabled={!input.trim() || isLoading}
              style={{
                backgroundColor: '#ff7d45',
                color: 'white',
                padding: '8px 16px',
                borderRadius: '6px',
                border: 'none',
                cursor: isLoading ? 'not-allowed' : 'pointer',
                opacity: isLoading ? 0.7 : 1
              }}
            >
              {isLoading ? 'Thinking...' : 'Ask'}
            </button>
          </Box>
        </Box>

        {/* Recent Chats */}
        <Box>
          <Box sx={styles.recentChatsLabel}>Recent Chats</Box>

          {/* Chat Items */}
          {[
            "My Weakest Subtopics",
            "Quant Prep Strategy",
            "Time management during test"
          ].map((chat, index) => (
            <Box key={index} sx={styles.chatItem}>
              <Box component="span" sx={styles.chatName}>{chat}</Box>
              <Box component="span" sx={styles.openLabel}>Open</Box>
            </Box>
          ))}
        </Box>
      </Box>}
      {analysisData && <RagResults
        chatHistory={chatHistory}
        input={input}
        handleSendMessage={handleSendMessage}
        isLoading={isLoading}
        setInput={setInput}
        userQuery={analysisData.userQuery}
        analysis={analysisData.analysis}
        performanceData={analysisData.performanceData}
        questionTypeData={analysisData.questionTypeData} />}
    </Box>
  );
};

export default AMA;

