import React, { useEffect, useRef } from 'react';
import './styles.scss'; // Import the SCSS file
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useSelector } from 'react-redux';

interface ChatMessage {
  analysis: string;
  timestamp: Date;
  performanceData?: any;
  questionTypeData?: any;
  userQuery?: string;
}

interface PerformanceData {
  sections: {
    name: string;
    accuracy: number;
  }[];
  questionTypes: {
    name: string;
    accuracy: number;
    angle: number;
  }[];
}

interface RagResultsProps {
  setInput?: (input: string) => void;
  userQuery?: string;
  analysis?: string;
  performanceData?: any;
  questionTypeData?: any;
  handleSendMessage?: () => void;
  isLoading?: boolean;
  input: string;
  chatHistory?: ChatMessage[];
}

const RagResults: React.FC<RagResultsProps> = ({
  setInput,
  userQuery,
  analysis,
  performanceData,
  questionTypeData,
  handleSendMessage,
  isLoading,
  input,
  chatHistory = []
}) => {
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [analysis, chatHistory]);

  // Sample data - use this as fallback if no data provided


  // Use provided data or fallback to sample data
  const chartData: PerformanceData = {
    sections: performanceData,
    questionTypes: (questionTypeData || [])?.map((type: any, index: number) => ({
      ...type,
      angle: (360 / (questionTypeData.length || 1)) * index
    }))
  };

  // Max value for radar chart
  const maxValue = 100;
  const centerX = 150;
  const centerY = 150;
  const radius = 120;

  // Convert polar coordinates to cartesian for radar chart
  const polarToCartesian = (angle: number, value: number): { x: number, y: number } => {
    const adjustedAngle = angle - 90; // Start from top (90 degrees offset)
    const radian = (adjustedAngle * Math.PI) / 180;
    const distance = (value / maxValue) * radius;
    return {
      x: centerX + distance * Math.cos(radian),
      y: centerY + distance * Math.sin(radian)
    };
  };

  // Generate radar chart points
  const generateRadarPath = (data: { name: string; accuracy: number; angle: number }[]): string => {
    return data.map((item, index) => {
      const { x, y } = polarToCartesian(item.angle, item.accuracy);
      return index === 0 ? `M ${x},${y}` : `L ${x},${y}`;
    }).join(' ') + ' Z';
  };

  // Generate axis lines for radar chart
  const generateAxisLines = (): JSX.Element[] => {
    return chartData.questionTypes.map((type, index) => {
      const { x, y } = polarToCartesian(type.angle, maxValue);
      return (
        <line
          key={`axis-${index}`}
          x1={centerX}
          y1={centerY}
          x2={x}
          y2={y}
          stroke="var(--text-color-1)"
          strokeOpacity="0.2"
          strokeWidth="1"
        />
      );
    });
  };

  // Generate concentric circles for radar chart
  const generateConcentricCircles = (): JSX.Element[] => {
    const steps = [25, 50, 75, 100];
    return steps.map((step, index) => (
      <circle
        key={`circle-${index}`}
        cx={centerX}
        cy={centerY}
        r={(step / maxValue) * radius}
        fill="none"
        stroke="var(--text-color-1)"
        strokeOpacity="0.1"
        strokeWidth="1"
      />
    ));
  };

  // Label positions for radar chart
  const generateLabels = (): JSX.Element[] => {
    return chartData.questionTypes.map((type, index) => {
      const { x, y } = polarToCartesian(type.angle, maxValue + 15);
      return (
        <text
          key={`label-${index}`}
          x={x}
          y={y}
          fill="var(--text-color-1)"
          fontSize="12"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {type.name}
        </text>
      );
    });
  };

  return (
    <div className="performance-dashboard">
      <div className="dashboard-layout">
        {/* Left side - Text and reply box */}
        <div className="left-panel">
          {/* Chat history container */}
          <div className="chat-history" ref={chatHistoryRef}>
            {[...chatHistory, { analysis: analysis, userQuery: userQuery }].map((chat, index) => (
              <div key={index} className="chat-message">
                <div className="user-query">
                  <div className="avatar">
                    <div className="avatar-text">{userDetail?.userName?.split(" ")?.[0]?.charAt(0)}{userDetail?.userName?.split(" ")?.[1]?.charAt(0)}</div>
                  </div>
                  <div className="query-text">
                    <p>{chat.userQuery}</p>
                  </div>
                </div>
                <div className="analysis-container">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                      <circle cx="8.5" cy="8.5" r="1.5"></circle>
                      <polyline points="21 15 16 10 5 21"></polyline>
                    </svg>
                  </div>
                  <div className="analysis-content">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        h3: ({ node, children, ...props }) => <h3 className="analysis-heading" {...props}>{children}</h3>,
                        p: ({ node, ...props }) => <p className="analysis-paragraph" {...props} />,
                        ul: ({ node, ...props }) => <ul className="analysis-list" {...props} />,
                        li: ({ node, ...props }) => <li className="analysis-list-item" {...props} />,
                        strong: ({ node, ...props }) => <strong className="analysis-bold" {...props} />,
                      }}
                    >
                      {chat.analysis || 'Analyzing your performance data...'}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Reply section */}
          <div className="reply-section">
            <div className="input-container">
              <input
                type="text"
                placeholder="Reply To Sage"
                className="reply-input"
                onChange={(e) => setInput && setInput(e.target.value)}
                disabled={isLoading}
              />
              <button
                onClick={handleSendMessage}
                disabled={!input.trim() || isLoading}
                className="send-button"
              >
                {isLoading ? 'Thinking...' : 'Ask'}
              </button>
            </div>
          </div>

          <div className="footer-text">
            Be Concise to get best results.
          </div>
        </div>

        {/* Right side - Charts */}
        {performanceData && questionTypeData && <div className="right-panel">
          {/* Bar chart section */}
          <div className="chart-section">
            <h2>Performance By Section</h2>
            <div className="bar-chart">
              {chartData.sections.map((section, index) => (
                <div key={index} className="bar-column">
                  <div
                    className="bar"
                    style={{ height: `${section.accuracy * 1.5}px` }}
                  ></div>
                  <div className="bar-label">{section.name}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Radar chart section */}
          <div className="chart-section">
            <h2>Avg. Accuracy by Question Type</h2>
            <div className="radar-chart">
              <svg width="300" height="300" viewBox="0 0 300 300">
                {/* Concentric circles */}
                {generateConcentricCircles()}

                {/* Axis lines */}
                {generateAxisLines()}

                {/* Data polygon */}
                <path
                  d={generateRadarPath(chartData.questionTypes)}
                  className="radar-area"
                />

                {/* Data points */}
                {chartData.questionTypes.map((item, index) => {
                  const { x, y } = polarToCartesian(item.angle, item.accuracy);
                  return (
                    <circle
                      key={`point-${index}`}
                      cx={x}
                      cy={y}
                      r="4"
                      className="radar-point"
                    />
                  );
                })}

                {/* Labels */}
                {generateLabels()}

                {/* Legend */}
                <g transform="translate(225, 270)">
                  <rect x="0" y="0" width="10" height="10" className="legend-color" />
                  <text x="15" y="8" fill="white" fontSize="10">Accuracy</text>
                </g>
              </svg>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default RagResults;