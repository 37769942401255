import React from "react";
import { ReactComponent as FileIcon } from "../../../assets/light/PageOrange.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from "framer-motion";
import "./styles.scss";
import SequentialTypeWriter from "../../Typewriter/sequential";
import AppButton from "../../AppButton";

interface IProps {
  data: any;
  onBack?: () => void;
  onNext?: () => void;
}

const AnalyseView: React.FC<IProps> = ({ data, onBack, onNext }) => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.4 } },
  };

  const Typewriter = SequentialTypeWriter();

  return (
    <motion.div
      className="analyze-view-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="file-container">
        <FileIcon />
        <div className="file-details">
          <Typewriter index={0} className="file-name">
            {data?.file?.name}
          </Typewriter>
          <Typewriter index={1} className="file-size">
            {`${data?.file?.size / 1000}  KB - 100% uploaded`}
          </Typewriter>
        </div>
      </div>

      <div className="view-cotainer">
        <Typewriter index={2} className="header">
          {data?.analysisReport?.title}
        </Typewriter>
        <div className="inner-content">
          <Typewriter index={3} className="inner-header">
            {data?.analysisReport?.subject}
          </Typewriter>
          <Typewriter index={4} className="exp-content">
            {data?.analysisReport?.message}
          </Typewriter>
        </div>

        {Object.keys(data?.analysisReport?.studyRecommendation).map(
          (title: string, i: number) => {
            return (
              <div className="study-details">
                <Typewriter index={i * 3 + 5} className="study-header">
                  {data?.analysisReport?.studyRecommendation?.[title]?.title}
                </Typewriter>
                <Typewriter className="inner-header" index={i * 3 + 6}>
                  {data?.analysisReport?.studyRecommendation?.[title]?.subject}
                </Typewriter>
                <Typewriter className="exp-content" index={i * 3 + 7}>
                  {data?.analysisReport?.studyRecommendation?.[title]?.message}
                </Typewriter>
              </div>
            );
          }
        )}

        {Object.keys(data?.analysisReport?.performance).map(
          (title: string, i: number) => {
            let idx =
              Object.keys(data?.analysisReport?.studyRecommendation).length *
                3 +
              4 +
              i * 3;
            return (
              <div className="study-details">
                <Typewriter index={idx + 1} className="study-header">
                  {data?.analysisReport?.performance?.[title]?.label}
                </Typewriter>
                <Typewriter index={idx + 2} className="base-value">
                  {data?.analysisReport?.performance?.[title]?.value}
                </Typewriter>
                <Typewriter index={idx + 3} className="exp-content">
                  {data?.analysisReport?.performance?.[title]?.comment}
                </Typewriter>
              </div>
            );
          }
        )}
      </div>

      <div className="navigation-buttons">
        <AppButton
          onClick={onBack}
          label="Back"
          className="nav-button back-button"
          icon={<ArrowBackIcon />}
        />
        <AppButton
          onClick={onNext}
          label="Continue"
          className="nav-button next-button"
          icon={<ArrowForwardIcon />}
        />
      </div>
    </motion.div>
  );
};

export default AnalyseView;
