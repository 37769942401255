import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "./styles.scss";
import SequentialTypeWriter from "../Typewriter/sequential";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ErrorLogService } from "../../services/errorLog.service";

const AnalyseHistoryView: React.FC = () => {
  const ErrorLogApis = new ErrorLogService();
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.4 } },
  };

  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [jobs, setJobs] = React.useState<any[]>([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const Typewriter = SequentialTypeWriter();

  const getAllJobs = async () => {
    setJobs(await ErrorLogApis.GetAllJobs());
  };

  useEffect(() => {
    getAllJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to render value based on its type
  const renderValue = (value: any): string => {
    if (typeof value === "object" && value !== null) {
      return Object.entries(value)
        .map(([key, val]) => `${key}: ${val}`)
        .join("\n");
    }
    return value?.toString() || "";
  };

  return (
    <div className="flex flex-col w-full h-full items-center">
      {jobs?.map((job: any) => (
        <Accordion
          key={job.id}
          className="w-full"
          expanded={expanded === job.id}
          onChange={handleChange(job.id)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <p className="header">
              <span>
                {new Date(job.createdat).toLocaleDateString()}{" "}
                {new Date(job.createdat).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <motion.div
              className="analyze-view-container"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="view-cotainer">
                <Typewriter index={0} className="header">
                  {job.message?.analysisReport?.title || ""}
                </Typewriter>
                <div className="inner-content">
                  <Typewriter index={0} className="inner-header">
                    {job.message?.analysisReport?.subject || ""}
                  </Typewriter>
                  <Typewriter index={0} className="exp-content">
                    {job.message?.analysisReport?.message || ""}
                  </Typewriter>
                </div>

                {job.message?.analysisReport?.performance &&
                  Object.entries(job.message.analysisReport.performance).map(
                    ([key, performance]: [string, any]) => (
                      <div key={key} className="study-details">
                        <Typewriter index={0} className="study-header">
                          {performance?.label || ""}
                        </Typewriter>
                        <Typewriter
                          index={0}
                          className="base-value whitespace-pre-line"
                        >
                          {renderValue(performance?.value)}
                        </Typewriter>
                        <Typewriter index={0} className="exp-content">
                          {performance?.comment || ""}
                        </Typewriter>
                      </div>
                    )
                  )}

                {job.message?.analysisReport?.studyRecommendation &&
                  Object.entries(
                    job.message.analysisReport.studyRecommendation
                  ).map(([key, recommendation]: [string, any]) => (
                    <div key={key} className="study-details">
                      <Typewriter index={0} className="study-header">
                        {recommendation?.title || ""}
                      </Typewriter>
                      <Typewriter className="inner-header" index={0}>
                        {recommendation?.subject || ""}
                      </Typewriter>
                      <Typewriter className="exp-content" index={0}>
                        {recommendation?.message || ""}
                      </Typewriter>
                    </div>
                  ))}
              </div>
            </motion.div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AnalyseHistoryView;
