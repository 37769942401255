/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import "./styles.scss";
import FileUpload from "../../components/FileUpload";
import AppButton from "../../components/AppButton";
import { ReactComponent as TrashCan } from "../../assets/light/TrashCan.svg";
import { ReactComponent as FileLogo } from "../../assets/light/Page.svg";
import HistoryIcon from "@mui/icons-material/History";
import AnalyseView from "../../components/analyse/view";
import { mockData } from "../../assets/data/mockData";
import { Loader } from "../../components/Loader";
import { ErrorLogService } from "../../services/errorLog.service";
import { generateHash, sendErrorDataToBackend } from "../../utils/helper";
import {
  // eGmatHeaders,
  errorLogTableHeaders,
  ForceReviewSettingEnum,
  // gmatClubHeaders,
  // gmatWhizHeaders,
  // mbaHeaders,
  SubscriptionPlane,
  SubscriptionStatus,
  TemplateId,
  templatePatterns,
  // testPrepHeaders,
} from "../../utils/constants";
import { errorMapper } from "../../utils/mapper";
import { useDispatch, useSelector } from "react-redux";
import { resetChart } from "../../redux/Reducer/chartDataReducer";
import Tesseract, { PSM } from "tesseract.js";
import { Jimp } from "jimp";
import AppTable from "../../components/Table";
import AnalyseHistoryView from "../../components/analyseHistory";
import { plans } from "../../utils/planDetails";
import { planType, UserDetailsTypes } from "../../utils/types";
import { toast } from "react-toastify";
import LockIcon from "@mui/icons-material/Lock";
import ErrorDialog from "../../components/DialogBox";
import { useNavigate } from "react-router-dom";

interface ISelectedFileDetails {
  file?: File;
  fileType?: string;
  fileTemplate?: string;
  fileData?: any;
}

const Analyser = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:800px)");
  const ErrorLogApis = new ErrorLogService();
  const dispatch = useDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const [analysed, setAnalysed] = useState(false);
  const [history, setHistory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [percent, setPercent] = useState(0);
  const intervalref = useRef<null | NodeJS.Timer>(null);
  const workerRef = useRef<null | Tesseract.Worker>(null);
  const [parsedFiles, setParsedFiles] = useState<ISelectedFileDetails[]>([]);
  const [analyzedData, setAnalyzedData] = useState([]);
  const [summary, setSummary] = useState(mockData);
  const [plan, setPlan] = useState<planType>(plans[SubscriptionPlane.Free]);
  const [totalLogs, setTotalLogs] = useState<number>(0);
  const [disableAnalyzeButton, setDisableAnalyzeButton] = useState<
    string | null
  >("");
  const [isDialogOpen, setIsDialogOpen] = useState<
    boolean
  >(false);

  const userDetail: UserDetailsTypes = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );

  useEffect(() => {
    if (!!userDetail?.forceReviewSetting) {
      if (userDetail?.forceReviewSetting === ForceReviewSettingEnum.All) {
        if (analyzedData.some((x: any) => !x.notes))
          setDisableAnalyzeButton("Need to add notes for all logs");
        else setDisableAnalyzeButton(null);
      } else if (
        userDetail?.forceReviewSetting === ForceReviewSettingEnum.Only_Incorrect
      ) {
        if (
          analyzedData.some(
            (x: any) =>
              (x?.solution || "")?.toLowerCase() === "correct" && !x.notes
          )
        )
          setDisableAnalyzeButton("Need to add notes for all Incorrect logs");
        else setDisableAnalyzeButton(null);
      } else setDisableAnalyzeButton(null);
    } else if (analyzedData.filter((x: any, i) => !x.is_uploadable)
      .length === analyzedData.length) {
      setDisableAnalyzeButton("Logs Already Uploaded!");
    }
    else setDisableAnalyzeButton(null);
  }, [analyzedData]);

  const errorService = new ErrorLogService();

  // const tableHeader = useMemo(() => {
  //   switch (parsedFiles?.[0]?.fileTemplate) {
  //     case "GMATClub":
  //       return gmatClubHeaders;
  //     case "gmatwhiz":
  //       return gmatWhizHeaders;
  //     case "e-gmat":
  //       return eGmatHeaders;
  //     case "targettestprep":
  //       return testPrepHeaders;
  //     case "mba":
  //       return mbaHeaders;
  //     default:
  //       return [];
  //   }
  // }, [analyzedData, parsedFiles]);

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleOnSave = async () => {
    let jobId = "";
    try {
      jobId = await sendErrorDataToBackend(
        analyzedData.filter((x: any, i) => x.is_uploadable),
        ErrorLogApis
      );
    } catch (error) {
      console.error(error);
    }
    return jobId;
  };

  const identifySource = (inputText: string) => {
    for (let [source, pattern] of Object.entries(templatePatterns)) {
      if (Array.isArray(pattern)) {
        for (let patt of pattern) {
          if (patt.test(inputText)) {
            return source;
          }
        }
      } else {
        if (pattern.test(inputText)) {
          return source;
        }
      }
    }

    return "Unknown source";
  };

  const convertImageToText = async (
    imageFile: File | string,
    added = false
  ) => {
    if (!workerRef.current)
      workerRef.current = await Tesseract.createWorker("eng");
    const worker = workerRef.current;
    await worker.setParameters({
      tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
      ...(added
        ? {
          tessedit_char_whitelist:
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789:()+-=/%@© ",
        }
        : {}),
    });
    const {
      data: { text },
    } = await worker.recognize(imageFile);
    return { data: text, source: identifySource(text), image: imageFile };
  };

  const fileExtractor = async (file: File) => {
    if (["image", "png", "img"].some((x) => file.type.includes(x))) {
      const { data, source } = await convertImageToText(file);
      return { type: "image", data: [data], source };
    } else {
      return {
        type: "excel",
        data: await processExcel(file),
        source: "GMATClub",
      };
    }
  };

  const preProcessImage = async (
    imageFile: File,
    template: TemplateId
  ): Promise<any> => {
    const imageBuffer = await imageFile.arrayBuffer();
    const image = await Jimp.read(imageBuffer);
    let processedImage;
    switch (template) {
      case TemplateId.GMATWHIZ:
        processedImage = await image
          .greyscale()
          .invert()
          .contrast(0.1)
          .brightness(0.2)
          .threshold({ max: 255 })
          .getBase64("image/png");
        break;
      case TemplateId.TESTPREP:
        processedImage = await image
          .contrast(0.1)
          .greyscale()
          .threshold({ max: 255 })
          .getBase64("image/png");
        break;

      default:
        break;
    }
    return processedImage || imageFile;
  };

  const processExcel = (file: File): Promise<any> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const json: any = XLSX.utils.sheet_to_json(firstSheet);
        resolve(json);
      };

      reader.onerror = (err) => {
        console.error(err);
        resolve("");
      };

      reader.readAsArrayBuffer(file);
    });
  };

  // const isWithinExceptedDays = (
  //   incomingDate: Date | string,
  //   days: number
  // ): boolean => {
  //   // Convert to Date object if it's a string
  //   const date =
  //     incomingDate instanceof Date ? incomingDate : new Date(incomingDate);

  //   // Check if the date is valid
  //   if (isNaN(date.getTime())) {
  //     return false;
  //   }

  //   const now = new Date();
  //   const sevenDaysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);

  //   // Check if the date is between sevenDaysAgo and now (inclusive)
  //   const res = date >= sevenDaysAgo && date <= now;
  //   return res;
  // };

  const onAnalyze = async (acceptedFiles: File[]) => {
    try {
      setFileLoading(true);
      const parsedData: ISelectedFileDetails[] = await Promise.all(
        acceptedFiles.map(async (each: File) => {
          const { data, source, type } = await fileExtractor(each);
          return {
            file: each,
            fileType: type,
            fileTemplate: source,
            fileData: data,
          };
        })
      );
      setParsedFiles([...structuredClone(parsedData)]);
      const filteredData: ISelectedFileDetails[] = parsedData.filter(
        (each) =>
          each.fileType === parsedData?.[0]?.fileType &&
          each.fileTemplate === parsedData?.[0]?.fileTemplate
      );
      let newParsed = (
        await Promise.all(
          filteredData.map(async (x) => {
            if (x.fileType === "excel") {
              return errorMapper(x.fileData, TemplateId.GMATClub);
            } else if (x.fileTemplate === "e-gmat") {
              return errorMapper(x.fileData as string[], TemplateId.EGMAT);
            } else if (x.fileTemplate === "targettestprep") {
              const processedFile: any = await preProcessImage(
                x.file as File,
                TemplateId.TESTPREP
              );
              const fileData = await convertImageToText(processedFile, true);
              return errorMapper([fileData.data], TemplateId.TESTPREP);
            } else if (x.fileTemplate === "gmatwhiz") {
              const processedFile: any = await preProcessImage(
                x.file as File,
                TemplateId.GMATWHIZ
              );
              const fileData = await convertImageToText(processedFile);
              return errorMapper([fileData.data], TemplateId.GMATWHIZ);
            } else if (x.fileTemplate === "mba") {
              return errorMapper(x.fileData, TemplateId.MBA);
            }
            return [];
          })
        )
      ).reduce((all, each) => [...all, ...each], []);

      newParsed = newParsed.map((x: any) => {
        // if (x?.date_attempted) {
        //   if (
        //     Number(totalLogs) === 0 &&
        //     !isWithinExceptedDays(x?.date_attempted, 7)
        //   ) {
        //     x.is_uploadable = "Skipping, Attended the question 7 days ago";
        //   } else if (
        //     Number(totalLogs) !== 0 &&
        //     !isWithinExceptedDays(x?.date_attempted, 2)
        //   ) {
        //     x.is_uploadable = "Skipping, Attended the question 2 days ago";
        //   }
        // }
        return x;
      });
      if (plan.noOfLogsAllowed - totalLogs >= newParsed.length) {
        newParsed = await Promise.all(newParsed.map(async (x: any) => ({ ...x, hash: await generateHash({ ...x, userId: userDetail.userId }) })))
        const hashes = newParsed.map((x: any) => x.hash)
        const res = await errorService.CheckHash(hashes);
        if (!!res?.data) {
          newParsed = await Promise.all(newParsed.map(async (x: any) => ({ ...x, is_uploadable: !res?.data?.some((y: string) => y === x.hash) })))
        }
        setAnalyzedData(newParsed);
        setFileLoading(false);
      } else {
        toast.warning(
          `Only ${plan.noOfLogsAllowed} rows are allowed for ${userDetail?.subTier} Tier users.`
        );
        setParsedFiles([]);
        setAnalyzedData([]);
        setFileLoading(false);
      }

      return newParsed;
    } catch (error: any) {
      setDisableAnalyzeButton(error.message);
      setParsedFiles([]);
      setAnalyzedData([]);
      setFileLoading(false);
      toast.error(error.message);
    }
  };

  const handleAnalyze = async () => {
    setLoading(true);
    const jobId = await handleOnSave();
    intervalref.current = setInterval(async () => {
      if (!!jobId) {
        const res = await ErrorLogApis.GetSummary(jobId);
        if (res.data.status === "Completed") {
          setSummary(res.data.message);
          setPercent(100);
        } else if (percent < 90) setPercent((prev) => prev + 5);
      } else if (percent < 90) setPercent((prev) => prev + 5);
    }, 2000);
  };

  useEffect(() => {
    if (percent === 100) {
      setTimeout(() => {
        setLoading(false);
        setAnalysed(true);
        clearInterval(intervalref.current as NodeJS.Timer);
        intervalref.current = null;
      }, 1000);
      dispatch(resetChart());
    }
  }, [percent, dispatch]);

  const getCurrentLogsCount = async () => {
    const res = await errorService.GetErrorLogCount();
    if (!!res?.data) {
      setTotalLogs(res?.data?.[0]?.count);
    } else {
      setTotalLogs(0);
    }
  };

  useEffect(() => {
    getCurrentLogsCount();
    setHistory(false);
    return () => {
      workerRef?.current?.terminate();
    };
  }, []);

  useEffect(() => {
    if (userDetail?.subStatus === SubscriptionStatus.Active) {
      setPlan(plans[userDetail?.subTier]);
    } else {
      setPlan(plans[SubscriptionPlane.Free]);
    }
  }, [userDetail?.subStatus, userDetail?.subTier]);

  useEffect(() => {
    if (files.length) onAnalyze(files);
    else {
      setParsedFiles([]);
      setAnalyzedData([]);
    }
  }, [files]);

  const handleBack = () => {
    setAnalysed(false);
    setFiles([]);
    setParsedFiles([]);
    setAnalyzedData([]);
    setSummary(mockData);
  };

  const handleNext = () => {
    navigate("/dashboard");
  };

  return (
    <Box className={`analyser-container ${analysed ? "view" : ""}`}>
      {!analysed && !history && (
        <div
          style={{
            height: "100%",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <HistoryIcon
                style={{ cursor: "pointer" }}
                onClick={() => setHistory(true)}
              />
            </div>
            <div></div>
            <div></div>
          </div>
          <div
            style={{
              height: "88vh",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box className="content-container">
              {plan.noOfLogsAllowed <= totalLogs && ( // Add your condition here
                <div className="lock-overlay">
                  <LockIcon />
                </div>
              )}
              <Box className="top-container">
                <p className="header">Analyse Error Log</p>
                <p className="hint">
                  Get instant analysis to help you move forward in your study.
                </p>
                {files.length > 0 ? (
                  <div
                    className="file-list"
                    style={{ width: matches ? "30rem" : "100%" }}
                  >
                    {files.map((file, index) => (
                      <div className="file-item" key={index}>
                        <FileLogo />
                        <div className="file-info">
                          <p>{file.name}</p>
                        </div>
                        <div className="file-actions">
                          <TrashCan onClick={() => handleRemoveFile(index)} />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <FileUpload
                    files={files}
                    onFilesSelected={(e: any) => setFiles(e)}
                    width={matches ? "30rem" : "100%"}
                    height={"100%"}
                    innerFiles={false}
                    accept=".csv,.xlsx,.jpg,.png"
                    multiple={true}
                  />
                )}
              </Box>
              <Box className="down-container">
                <p className="hint">
                  {parsedFiles.length
                    ? `${parsedFiles[0]?.fileTemplate} template is chosen`
                    : ""}
                </p>
                <AppButton
                  tooltip={!!disableAnalyzeButton ? disableAnalyzeButton : ""}
                  onClick={() => {
                    if (!!analyzedData.filter((x: any, i) => !x.is_uploadable)
                      .length) {
                      setIsDialogOpen(true)
                    } else {
                      handleAnalyze()
                    }
                  }}
                  label="Analyze"
                  className={
                    !files.length ||
                      !parsedFiles.length ||
                      (analyzedData.filter((x: any, i) => !x.is_uploadable)
                        .length === analyzedData.length)
                      ||
                      !!disableAnalyzeButton
                      ? "analyze-button"
                      : "active-button"
                  }
                  disabled={
                    !files.length ||
                    !parsedFiles.length ||
                    (analyzedData.filter((x: any, i) => !x.is_uploadable)
                      .length === analyzedData.length)
                    ||
                    !!disableAnalyzeButton
                  }
                />
              </Box>
              {!!analyzedData?.length && (
                <AppTable
                  columns={errorLogTableHeaders}
                  rows={analyzedData || []}
                  setRows={setAnalyzedData}
                />
              )}
              <Loader open={fileLoading} />
            </Box>
          </div>
        </div>
      )}
      {analysed && (
        <AnalyseView
          data={{ ...summary, file: files[0] }}
          onBack={handleBack}
          onNext={handleNext}
        />
      )}
      {history && <AnalyseHistoryView />}
      <Loader open={loading} progress={true} progressPercent={percent} />
      <ErrorDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSelect={() => {
          setIsDialogOpen(false)
          handleAnalyze()
        }}
        buttonName={"Continue"}
        description={"Some of the logs are already uploaded. Do you want to continue and upload the missing one's?"}
        header={"Alert"}
      />
    </Box>
  );
};

export default Analyser;
