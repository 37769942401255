import { Box, Divider } from "@mui/material";
import { useState } from "react";
import AppDatePicker from "../../DatePicker";
import dayjs from "dayjs";
import TextBox from "../../TextBox";
import RatingComponent from "../../RatingComponent";
import AppButton from "../../AppButton";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../redux/Reducer/userReducer";
import { UserService } from "../../../services/user.service";
import { toast } from "react-toastify";
import AppToggle from "../../Toggle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { ForceReviewSettingEnum } from "../../../utils/constants";
import { requestFCMPermission } from "../../../utils/firebase";
import { SubscribeService } from "../../../services/subscribe.service";

const AllSettings = () => {
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );

  const dispatch = useDispatch();

  const [userData, setUserData] = useState<any>(userDetail);
  const [loading, setLoading] = useState(false);

  const userService = new UserService();
  const subscribeService = new SubscribeService();

  const handleSave = async () => {
    setLoading(true);
    dispatch(
      updateUserData(userData)
    );
    await userService.UpdateUser(userData);
    toast.success("Data Updated");
    setLoading(false);
  };

  const toOddMultipleOfFive = (num: number): number => {
    let nearestMultipleOfFive = Math.round(num / 5) * 5;
    if (nearestMultipleOfFive % 2 === 0) {
      nearestMultipleOfFive += 5;
    }
    return nearestMultipleOfFive;
  };

  const handleNotificationToggle = async (checked: boolean) => {
    setLoading(true);
    try {
      if (checked) {
        // Request Firebase notification permission
        const fcmToken = await requestFCMPermission();
        if (fcmToken) {
          await subscribeService.saveFCMTokenToServer(fcmToken);
          toast.success("Push notifications enabled");
        }
      } else {
        await subscribeService.saveFCMTokenToServer("");
        // Handle unsubscribe if needed
        toast.success("Push notifications disabled");
      }
    } catch (error) {
      console.error("Error toggling notifications:", error);
      toast.error("Failed to update notification settings");
    }
    setLoading(false);
  };

  return (
    <Box className="main-settings-container">
      <p className="question">Proposed Exam date?</p>
      <AppDatePicker
        className="exam-date"
        onChange={(e) =>
          setUserData((prev: any) => ({ ...prev, examDate: e?.utc()?.format() || new Date().toUTCString() }))
        }
        value={dayjs(userData?.examDate as string)}
      />
      <Divider
        className="divider"
        sx={{ width: "100%", marginY: "20px" }}
      ></Divider>
      <p className="question">Target score</p>
      <Box className="input-container">
        <TextBox
          className="mark-text"
          value={userData.exceptedGmatScore}
          onChange={(e) => setUserData((prev: any) => ({ ...prev, exceptedGmatScore: Number(e.target.value) }))}
          onBlur={() => setUserData((prev: any) => ({ ...prev, exceptedGmatScore: toOddMultipleOfFive(prev) }))}
        />
        <RatingComponent value={userData.exceptedGmatScore} onChange={(e: number) => setUserData((prev: any) => ({ ...prev, exceptedGmatScore: e }))} />
      </Box>
      <Divider
        className="divider"
        sx={{ width: "100%", marginY: "20px" }}
      ></Divider>
      <Box className="top-box">
        <Box className="question-container">
          <Box className="text-container">
            <p className="question">Push Notifications</p>
            <p className="subtitle">Receive notifications for important updates</p>
          </Box>
          <AppToggle
            loading={loading}
            checked={!!userData?.fcmToken}
            onChange={(e) => handleNotificationToggle(e.target.checked)}
          />
        </Box>
        <Divider
          className="divider"
          sx={{ width: "100%", marginY: "20px" }}
        ></Divider>
        <Box className="question-container">
          <Box className="text-container">
            <p className="question">Notification Time Window</p>
            <div style={{ marginTop: "20px", display: "flex", gap: "20px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  views={["hours", "minutes"]}
                  value={dayjs(userData?.notificationSettings?.startTime)}
                  onChange={(newValue) =>
                    setUserData((prev: any) => ({
                      ...prev,
                      notificationSettings: {
                        ...prev.notificationSettings,
                        startTime: dayjs(newValue).toISOString()
                      }
                    }))
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  views={["hours", "minutes"]}
                  value={dayjs(userData?.notificationSettings?.endTime)}
                  onChange={(newValue) =>
                    setUserData((prev: any) => ({
                      ...prev,
                      notificationSettings: {
                        ...prev.notificationSettings,
                        endTime: dayjs(newValue).toISOString()
                      }
                    }))
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </div>
          </Box>
        </Box>
      </Box>
      <Divider
        className="divider"
        sx={{ width: "100%", marginY: "20px" }}
      ></Divider>
      <Box className="top-box2">
        <Box className="question-container2">
          <Box className="text-container2">
            <p className="question">Is Force Review Enabled?</p>
          </Box>
          <AppToggle
            loading={loading}
            checked={!!userData?.forceReviewSetting}
            onChange={(e) => {
              if (e.target?.checked) {
                setUserData((prev: any) => ({ ...prev, forceReviewSetting: ForceReviewSettingEnum.Only_Incorrect }))
              } else setUserData((prev: any) => ({ ...prev, forceReviewSetting: null }))
            }}
          />
        </Box>

        {!!userData?.forceReviewSetting && (
          <Box className="sub-settings2">
            <Box className="question-container2">
              <Box className="text-container2">
                <p className="question">For All Logs</p>
              </Box>
              <AppToggle
                loading={loading}
                checked={userData?.forceReviewSetting === ForceReviewSettingEnum.All}
                onChange={(e) => {
                  if (e.target?.checked) {
                    setUserData((prev: any) => ({ ...prev, forceReviewSetting: ForceReviewSettingEnum.All }))
                  } else setUserData((prev: any) => ({ ...prev, forceReviewSetting: ForceReviewSettingEnum.Only_Incorrect }))
                }}
              />
            </Box>
            <Box className="question-container2">
              <Box className="text-container2">
                <p className="question">For Only Incorrect Once</p>
              </Box>
              <AppToggle
                loading={loading}
                checked={userData?.forceReviewSetting === ForceReviewSettingEnum.Only_Incorrect}
                onChange={(e) => {
                  if (e.target?.checked) {
                    setUserData((prev: any) => ({ ...prev, forceReviewSetting: ForceReviewSettingEnum.Only_Incorrect }))
                  } else setUserData((prev: any) => ({ ...prev, forceReviewSetting: ForceReviewSettingEnum.All }))
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box className="btn-container">
        <AppButton
          label="Save Changes"
          onClick={handleSave}
          className="save-btn"
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default AllSettings;
