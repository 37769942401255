import React from 'react';
import './style.scss';

interface ErrorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: () => void;
  buttonName: string
  description: string
  header: string
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  isOpen,
  onClose,
  onSelect,
  buttonName,
  description,
  header
}) => {
  if (!isOpen) return null;

  return (
    <div className="error-dialog-overlay">
      <div className="error-dialog-container">
        <div className="error-dialog-header">
          <h2 className="error-dialog-title">{header}</h2>
          <button
            onClick={onClose}
            className="error-dialog-close-button"
          >
            ×
          </button>
        </div>

        <p className="error-dialog-message">
          {description}
        </p>

        <div className="error-dialog-actions">
          <button
            onClick={onClose}
            className="error-dialog-button cancel-button"
          >
            Cancel
          </button>

          <button
            onClick={onSelect}
            className="error-dialog-button select-button"
          >
            {buttonName}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorDialog;