import { BaseService } from "./base.service";


export class NotificationsService extends BaseService {
    public async GetNotifications() {
        try {
            const { data, status } = await this.httpClient.get("notification");

            if (status === 200) {
                return data;
            }
        }
        catch (error) {
            console.error('Error fetching notifications:', error);
            throw error;
        }
    }

    public async MarkAsRead(notificationId: string) {
        try {
            const { data, status } = await this.httpClient.put(`notification/${notificationId}`, {
                isread: true
            });

            if (status === 200) {
                return data;
            }
        }
        catch (error) {
            console.error('Error marking notification as read:', error);
            throw error;
        }
    }
}