import { TemplateId } from "../constants";
import { Questiontypes } from "../gmatMapper";

const convertToMinutesAndSeconds = (time: any) => {
    const minutes = time[0] || '0';
    const seconds = time[1];
    return `${minutes}.${seconds.padStart(2, '0')}`;
};

const parseQuestions = (text: string, template: string) => {
    const data = text.split("\n").filter(x => x.split(" ")?.[0]?.length > 0 && x.split(" ")?.[0] !== "Question")
    const parsedData = data.map(entry => {
        let i = 0
        const parts = entry.split(" ");
        const q_no = parts[i];
        i += 1
        const time_taken = convertToMinutesAndSeconds(parts[i].split("."));
        i += 1
        const iscorrect = parts[i];
        i += 1
        let question_type = ""
        if (Questiontypes.includes(`${parts[3]} ${parts[4]}`)) {
            question_type = `${parts[3]} ${parts[4]}`
            i += 2
        } else {
            question_type = parts[3]
            i += 1
        }
        const question_skillset = [...parts].slice(i, parts.length).join(" ")

        return {
            q_no: Number(q_no),
            question_type,
            time_taken,
            error_template_id: template,
            solution: iscorrect,
            question_skillset,
            date_attempted: new Date().toISOString()
        };
    });
    return parsedData
}

export const mbaMapper = (textStrArr: string[], template: TemplateId) => {
    return textStrArr.reduce((arr: any[], textStr) => {
        return [...arr, ...parseQuestions(textStr, template)]
    }, [])
}