import { BaseService } from "./base.service";

export class RAGService extends BaseService {
    private baseUrl: string;
    private headers: Record<string, string>;

    constructor() {
        super();
        this.baseUrl = process.env.REACT_APP_DREAMFYER_URL || '';
        this.headers = {
            'Authorization': `Bearer ${localStorage.getItem('token') || ''}`
        };
    }

    public async sendMessage(userId: string, query: string, chatId?: string, onStream?: (data: any) => void) {
        try {
            const response = await fetch(`${this.baseUrl}rag_chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...this.headers
                },
                body: JSON.stringify({
                    userId,
                    query,
                    chatId
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (onStream) {
                const reader = response.body?.getReader();
                const decoder = new TextDecoder();

                if (!reader) {
                    throw new Error('No reader available');
                }

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    const chunk = decoder.decode(value);
                    const lines = chunk.split('\n');

                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            const data = JSON.parse(line.slice(6));
                            onStream(data);
                        }
                    }
                }
            } else {
                const { data, status } = await this.httpClient.post("rag_chat", {
                    userId,
                    query,
                    chatId
                });

                if (status === 200) {
                    return data;
                }
            }
        }
        catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    }

    public async getChatHistory(userId: string, chatId?: string) {
        try {
            const { data, status } = await this.httpClient.post("rag_chat_history", {
                userId,
                chatId
            });

            if (status === 200) {
                return data;
            }
        }
        catch (error) {
            console.error('Error fetching chat history:', error);
            throw error;
        }
    }
} 