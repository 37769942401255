import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { ReactComponent as BackIcon } from "../../assets/light/Arrow.svg";
import "./styles.scss";
import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

interface IProps extends ButtonProps {
  className?: string;
  customVariant?:
    | "cancel"
    | "back"
    | "analyse"
    | "custom-outlined"
    | "disabled";
  label: string;
  loading?: boolean;
  tooltip?: string; // New prop for tooltip text
  tooltipPlacement?: "top" | "right" | "bottom" | "left"; // Optional tooltip placement
}

const AppButton: React.FC<IProps> = ({
  className,
  label,
  customVariant,
  loading,
  variant = "contained",
  tooltip,
  tooltipPlacement = "top",
  ...rest
}) => {
  // Create the base button element
  const buttonContent = loading ? (
    <CircularProgress color="inherit" size="25px" />
  ) : (
    label
  );

  // Check if tooltip is provided
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement={tooltipPlacement} arrow>
        <span>
          <Button
            variant={variant}
            className={`${className || ""} ${
              customVariant ? `button-${customVariant}` : ""
            } common-button`}
            startIcon={customVariant === "back" ? <BackIcon /> : undefined}
            {...rest}
          >
            {buttonContent}
          </Button>
        </span>
      </Tooltip>
    );
  }

  // Return button without tooltip
  return (
    <Button
      variant={variant}
      className={`${className || ""} ${
        customVariant ? `button-${customVariant}` : ""
      } common-button`}
      startIcon={customVariant === "back" ? <BackIcon /> : undefined}
      {...rest}
    >
      {buttonContent}
    </Button>
  );
};

export default AppButton;
